<!--  -->
<template>
      <div class='curriculumTime flex-align-center'>
            <div class="curriculumFrame flex-column-center">
                  <div class="courseTitle">余秋雨的课堂0414-4582-05</div>
                  <div class="lecturer flex-align-between">
                        <img src="../../assets/live/alarmClock.png">
                        <span>主讲：余秋雨</span>
                  </div>
                  <div class="subject flex-align-between">
                        <div class="timeHorizon flex-column-center">
                              <span class="time">10:05</span>
                              <span class="date">2022-04-14</span>
                        </div>
                        <div class="intervalFrame flex-column-center">
                              <div class="intervalTime">05分钟</div>
                              <div class="minuteLine"></div>
                        </div>
                        <div class="timeHorizon flex-column-center">
                              <span class="time">10:10</span>
                              <span class="date">2022-04-14</span>
                        </div>
                  </div>
                  <div class="joinClass flex-column-center" v-if="classroomStatus == 1">
                        <span>加入课堂</span>
                        <span>（未开课）</span>
                  </div>
                  <div class="joinClass  flex-column-center" :class="{'classforbid':classroomStatus == 2}"
                        v-else-if="classroomStatus == 2">
                        <span>课堂已下课</span>
                  </div>
                  <div class="joinClass flex-column-center" :class="{'classforbid':classroomStatus == 3}" v-else>
                        <span>课堂已过期</span>
                  </div>
            </div>
      </div>
</template>

<script>
      export default {
            data() {
                  return {
                        classroomStatus: 2
                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {

            }
      }
</script>
<style lang='less' scoped>
      @import './less/curriculumTime';
</style>